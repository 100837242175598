import { BaseEditor, BaseElement } from 'slate'

export function withCustom<T extends BaseEditor>(editor: T): T {
  const { isInline } = editor

  editor.isInline = ((element: { type: string } & BaseElement) => {
    return (
      [
        'link',
        'button-custom',
        'image-custom',
        'image-uploaded',
        'video-custom',
        'heading-one',
        'heading-two',
      ].includes(element.type) || isInline(element)
    )
  }) as any

  return editor
}
