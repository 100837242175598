'use client'
import { motion } from 'framer-motion'
import { useSlate } from 'slate-react'
import { isMarkActive, toggleMark } from '../funcitons'
import React from 'react'

export function MarkButton({
  format,
  children,
  className,
}: {
  format: string
  children: React.ReactNode
  className?: string
}) {
  const editor = useSlate()
  const isActive = isMarkActive(editor, format)
  return (
    <motion.div whileTap={{ scale: 0.95 }}>
      <button
        onMouseDown={(event) => {
          event.preventDefault()
          toggleMark(editor, format)
        }}
        className={`toolbar-button-container flex h-[25px] w-[25px] cursor-pointer items-center justify-center rounded-[5px] text-center text-gray-600 hover:bg-gray-200 ${isActive ? 'active' : ''}${className ? ` ${className}` : ''}`}>
        {children}
      </button>
    </motion.div>
  )
}
