'use client'

import { useEffect, useState, ReactNode } from 'react'
import { useFocused, useSelected } from 'slate-react'
import { MagnifyingGlassSolid } from '../icons'

export function ImageFile({
  fileId,
  attributes,
  children,
  style,
}: {
  fileId: number
  attributes?: any
  style?: any
  children?: ReactNode
}) {
  const selected = useSelected()
  const focused = useFocused()

  const [fileUrl, setFileUrl] = useState<string | null>()
  const [isLoading, setIsLoading] = useState(false)

  const fetchFileUrl = async (): Promise<string | undefined> => {
    console.log('Fetching file url...', fileId)
    setIsLoading(true)
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_EXP_URL}/files/pre-signed?file=${fileId}`,
      {
        method: 'GET',
      }
    )
    const data = await response.text()

    setIsLoading(false)
    if (data) {
      return data
    }

    return undefined
  }

  useEffect(() => {
    if (!fileUrl && fileId) {
      console.log('Loading fileId (ImageFile)', { fileId })

      fetchFileUrl().then((data) => setFileUrl(data))
    }
  }, [fileId, fileUrl])

  return (
    <div>
      {fileUrl ? (
        <a target={'_blank'} className={'flex cursor-pointer'} href={fileUrl}>
          <span
            {...attributes}
            style={{
              boxShadow: selected && focused ? '0 0 0 1px #B4D5FF' : 'none',
              ...style,
            }}>
            <div className="group relative mx-auto h-auto w-full">
              <img
                onClick={(e) => {
                  window.open(fileUrl, '_blank')
                  e.stopPropagation()
                  e.preventDefault()
                }}
                className=" rounded-lg object-cover"
                contentEditable={false}
                src={fileUrl}
                style={{
                  display: 'block',
                  maxWidth: '100%',
                  maxHeight: '20em',
                }}
              />
              <div className="invisible absolute bottom-2 right-2 flex h-8 w-8 rounded-md border border-[#D3D3D3] group-hover:visible">
                <MagnifyingGlassSolid
                  onClick={(e) => {
                    window.open(fileUrl, '_blank')
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                  className={''}
                />
              </div>
            </div>
            {children}
          </span>
        </a>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}
