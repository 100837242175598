import { Button, ButtonProps } from './button'

export function ReplyButton({ children, onClick }: ButtonProps) {
  return (
    <Button
      className="flex cursor-pointer flex-row items-center justify-center gap-2 text-blue-secondary"
      onClick={onClick}>
      <ReplyIcon />
      {children}
    </Button>
  )
}

export const ReplyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={13}
    fill="none"
    {...props}>
    <path
      fill="#5368E5"
      d="M.218 4.316 4.833.16c.404-.364 1.04-.068 1.04.497v2.189c4.212.05 7.552.93 7.552 5.093 0 1.68-1.038 3.344-2.185 4.214-.358.272-.869-.07-.736-.51 1.188-3.964-.564-5.017-4.63-5.078v2.404c0 .566-.638.86-1.041.496L.218 5.31a.675.675 0 0 1 0-.993Z"
    />
  </svg>
)
