'use client'
import React, { PropsWithChildren } from 'react'
import { CloseCross } from '../icons'
import ButtonAdminComponent, { ButtonStyle } from './button-admin'
import clsx from 'clsx'
import * as DialogPrimitive from '@radix-ui/react-dialog'

export type ModalGenericProps = PropsWithChildren & {
  onCloseModal: () => void
  isLoading: boolean
  isButtonDisabled?: boolean
  onSubmit: () => void
  onSubmitSecondary?: () => void
  icon?: React.JSX.Element
  title: string | React.JSX.Element
  buttonContent: string
  secondaryButtonContent?: string
  trigger?: React.ReactNode
  className?: string
  handleOpenChange?: (val: boolean) => void
  isOpen?: boolean
  buttonStyle?: ButtonStyle
}

export function ModalGeneric({
  handleOpenChange,
  isOpen,
  trigger,
  onCloseModal,
  isButtonDisabled = false,
  isLoading,
  onSubmit,
  icon,
  title,
  buttonContent,
  className,
  secondaryButtonContent,
  onSubmitSecondary,
  buttonStyle,
  children,
}: ModalGenericProps) {
  return (
    <DialogPrimitive.Root open={isOpen} onOpenChange={handleOpenChange}>
      <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
      <DialogPrimitive.Portal>
        <DialogPrimitive.Close asChild>
          <DialogPrimitive.Overlay className="pointer-events-auto fixed right-0 top-0 z-50 h-screen w-screen origin-bottom animate-modal-overlay bg-[rgba(0,0,0,0.4)] bp1:transform-none bp1:animate-none bp1:p-4 bp1:opacity-100" />
        </DialogPrimitive.Close>
        <DialogPrimitive.Content
          className={clsx(
            'fixed left-2/4 top-20 z-50 w-[640px] origin-[0_bottom_0] -translate-x-1/2 animate-modal-content overflow-hidden rounded-xl border border-[rgba(0,0,0,0.1)] bg-white focus:outline-none',
            'bp2:max-w-[90%]',
            className
          )}>
          <div className={'h-fit w-full'}>
            <div className={'flex flex-col'}>
              <div className="flex flex-col">
                <div className="flex flex-col justify-center overflow-auto p-6">
                  {icon && <div className={'m-auto flex pb-12'}>{icon}</div>}

                  <div className={'flex w-full flex-col gap-4'}>
                    <div className={'flex w-full justify-between'}>
                      <p
                        className={
                          'my-auto text-[16px] font-semibold tracking-[-0.1px] text-[#0E0E0E] text-primary'
                        }>
                        {title}
                      </p>

                      <div
                        className={'float-right cursor-pointer p-5'}
                        onClick={async () => {
                          onCloseModal()
                        }}>
                        <CloseCross className="fill-black stroke-black" />
                      </div>
                    </div>

                    <div
                      className={
                        'flex flex-col gap-5 text-[14px] font-normal text-[#0E0E0E]'
                      }>
                      {children}
                    </div>
                  </div>
                </div>

                <div className="flex h-fit w-full items-center justify-end gap-4 border-[1px] border-solid border-t-[#EFEFEF] p-4">
                  <div className={'flex flex-row gap-2'}>
                    {secondaryButtonContent && (
                      <p
                        className={
                          'my-auto cursor-pointer p-2 text-[16px] font-medium opacity-60'
                        }
                        onClick={onSubmitSecondary}>
                        {secondaryButtonContent}
                      </p>
                    )}

                    <ButtonAdminComponent
                      text={buttonContent}
                      onClick={onSubmit}
                      // icon={<PlusButton className={'fill-white'} width={11}/>}
                      loading={isLoading}
                      disabled={isButtonDisabled}
                      className={'w-fit'}
                      style={buttonStyle}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  )
}
