'use client'
import React, { useState } from 'react'
import { useSlate } from 'slate-react'
import { addNode, removeElement } from '../services/node'
import { ButtonForm, ModalButton } from './modal-button'
import { motion } from 'framer-motion'
import { isBlockActive } from '../funcitons'
import { ButtonIcon } from '@radix-ui/react-icons'

export function ButtonMark({
  className,
  format,
}: {
  className?: string
  format: string
}) {
  const [open, setOpen] = useState(false)
  const editor = useSlate()
  const [isActive, node] = isBlockActive(editor, format)

  const onConfirmValue = (value: ButtonForm) => {
    addNode(editor, {
      type: format,
      text: '',
      ...value,
    })

    setOpen(false)
  }

  const onRemoveButton = () => {
    removeElement(editor, format)
    setOpen(false)
  }

  return (
    <ModalButton
      open={open}
      url={isActive && node ? node?.url : undefined}
      bgColor={isActive && node ? node?.bgColor : undefined}
      textColor={isActive && node ? node?.textColor : undefined}
      openInNewTab={isActive && node ? node?.openInNewTab : undefined}
      onOpenChange={setOpen}
      onConfirmValue={onConfirmValue}
      onRemoveButton={onRemoveButton}>
      <motion.button
        whileTap={{ scale: 0.95 }}
        className={`toolbar-button${isActive ? ' active' : ''}${className ? ` ${className}` : ''}`}>
        <ButtonIcon className={''} />
      </motion.button>
    </ModalButton>
  )
}
