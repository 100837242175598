'use client'
import { htmlToSlate, slateToHtml } from '@slate-serializers/html'
import { withHistory } from 'slate-history'
import { createContext, useContext, useMemo } from 'react'
import { createEditor, Descendant } from 'slate'
import { Slate, withReact } from 'slate-react'
import { withCustom } from './plugins/with-custom'

export type InitialValue = string | Descendant[]

export type EditorProviderProps = {
  children: React.ReactNode
  initialValue?: InitialValue
  onChange?: (value: string) => void
  onClickAction?: () => Promise<void>
}
const EditorContext = createContext<{
  onClickAction?: () => Promise<void>
}>({
  onClickAction: async () => {},
})

export const useEditorProvider = () => useContext(EditorContext)

export function EditorProvider({
  children,
  initialValue,
  onChange,
  onClickAction,
}: EditorProviderProps) {
  const editor = useMemo(
    () => withCustom(withReact(withHistory(createEditor()))),
    []
  )

  const onSlateChange = (value: Descendant[]) => {
    onChange && onChange(slateToHtml(value))
  }

  return (
    <EditorContext.Provider
      value={{
        onClickAction,
      }}>
      <Slate
        editor={editor}
        initialValue={parseToSlateInitialValue(initialValue)}
        onChange={onSlateChange}>
        {children}
      </Slate>
    </EditorContext.Provider>
  )
}

function parseToSlateInitialValue(initialValue?: Descendant[] | string) {
  return initialValue
    ? typeof initialValue === 'string'
      ? !containHTMLTags(initialValue)
        ? createDescendentFromInitialValue(initialValue)
        : htmlToSlate(initialValue)
      : initialValue
    : createAnEmptyDescendent()
}

function createAnEmptyDescendent() {
  return [{ type: 'p', children: [{ text: '' }] }]
}

function createDescendentFromInitialValue(initialValue: string) {
  return initialValue.split('\n').map((text) => {
    return { type: 'p', children: [{ text }] }
  })
}

function containHTMLTags(value: string) {
  return /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/g.test(value)
}
