import { Link } from './link'
import { useSlate } from 'slate-react'

export const CustomButton = ({
  attributes,
  children,
  element,
  style,
  onClickAction,
}) => {
  return (
    <span className="button-container">
      <Link
        attributes={attributes}
        element={element}
        style={style}
        onClickAction={async () => {
          if (onClickAction) await onClickAction()
        }}>
        <span
          type={'button'}
          disabled={false}
          style={{
            backgroundColor: element.bgColor,
            color: element.textColor,
            ...style,
          }}
          className={`flex w-fit flex-row items-center justify-center gap-2 rounded-full px-6 py-2 font-semibold focus:outline-none`}
          {...attributes}>
          {children}
        </span>
      </Link>
    </span>
  )
}
