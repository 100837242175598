'use client'
import { motion } from 'framer-motion'
import { MouseEventHandler } from 'react'
import clsx from 'clsx'
export type SendButtonProps = {
  children: React.ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  className?: string
}

export function SendButton({
  children,
  onClick,
  disabled,
  className,
}: SendButtonProps) {
  return (
    <div
      className={clsx(
        'flex h-[29px] cursor-pointer items-center justify-center rounded-[8px] bg-blue-secondary px-4 text-[10px] leading-6 text-white',
        className
      )}>
      <motion.button
        whileTap={{ scale: 0.95 }}
        disabled={disabled}
        onClick={onClick}
        className="send-button">
        {children}
      </motion.button>
    </div>
  )
}
