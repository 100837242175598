import { forwardRef } from 'react'
import { Button, ButtonProps } from './button'
import '../comment-view.css'

export const DeleteButton = forwardRef(function DeleteButtonImple(
  { children, onClick, className }: ButtonProps,
  ref
) {
  return (
    <Button
      onClick={onClick}
      className={
        'flex cursor-pointer flex-row items-center justify-center gap-2 text-[#ED6368]' +
        (className ? ' ' + className : '')
      }
      ref={ref as any}>
      <DeleteIcon />
      {children}
    </Button>
  )
})

export const DeleteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    {...props}>
    <path
      fill="#000"
      fillOpacity={0.6}
      d="m2.563 9.444.599-.04-.6.04Zm6.874 0 .599.04-.599-.04ZM1 2.067a.6.6 0 0 0 0 1.2v-1.2Zm10 1.2a.6.6 0 1 0 0-1.2v1.2ZM5.489 5.444a.6.6 0 0 0-1.2 0h1.2Zm-1.2 2.778a.6.6 0 0 0 1.2 0h-1.2ZM7.71 5.444a.6.6 0 0 0-1.2 0h1.2Zm-1.2 2.778a.6.6 0 0 0 1.2 0h-1.2Zm1.06-5.406a.6.6 0 0 0 1.162-.299l-1.162.3Zm-6.059-.11.452 6.778 1.198-.08-.452-6.777-1.198.08ZM4.226 11.6h3.548v-1.2H4.226v1.2Zm5.81-2.116.452-6.777-1.198-.08-.452 6.777 1.198.08Zm-.147-7.417H2.11v1.2H9.89v-1.2ZM1 3.267h1.111v-1.2H1v1.2Zm8.889 0H11v-1.2H9.889v1.2ZM7.774 11.6a2.267 2.267 0 0 0 2.262-2.116l-1.198-.08c-.037.56-.502.996-1.064.996v1.2Zm-5.81-2.116A2.267 2.267 0 0 0 4.226 11.6v-1.2a1.067 1.067 0 0 1-1.064-.996l-1.198.08Zm2.325-4.04v2.778h1.2V5.444h-1.2Zm2.222 0v2.778h1.2V5.444h-1.2ZM6 1.6c.755 0 1.391.516 1.571 1.216l1.162-.299A2.823 2.823 0 0 0 6 .4v1.2ZM4.429 2.816C4.609 2.116 5.245 1.6 6 1.6V.4c-1.316 0-2.42.9-2.733 2.117l1.162.3Z"
    />
  </svg>
)
