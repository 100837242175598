import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-dialog'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import '../link/dialog.css'
import { SketchPicker } from 'react-color'
import { Switch } from '../../switch'

const buttonFormSchema = z.object({
  url: z.string().url(),
  bgColor: z.string(),
  textColor: z.string(),
  openInNewTab: z.boolean(),
})

export type ButtonForm = z.infer<typeof buttonFormSchema>

export function ModalButton({
  children,
  url,
  bgColor = '#5368E5',
  textColor = '#fff',
  openInNewTab = false,
  onConfirmValue,
  onRemoveButton,
  open,
  onOpenChange,
}: {
  children: React.ReactNode
  url?: string
  bgColor?: string
  textColor?: string
  openInNewTab?: boolean
  onConfirmValue?: (value: ButtonForm) => void
  onRemoveButton?: () => void
  open: boolean
  onOpenChange: (open: boolean) => void
}) {
  const [colorPickerOpen, setColorPickerOpen] = useState(false)
  const [colorPicker, setColorPicker] = useState(bgColor)
  const [colorPickerTextOpen, setColorPickerTextOpen] = useState(false)
  const [colorPickerText, setColorPickerText] = useState(textColor)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<ButtonForm>({
    resolver: zodResolver(buttonFormSchema),
  })
  const watchOpenInNewTab = watch('openInNewTab')

  const onSubmit = (data: ButtonForm) => {
    onConfirmValue?.({ ...data })
    onOpenChange(false)
  }

  const handleChangeSwitch = (active: boolean) => {
    setValue('openInNewTab', active)
  }

  useEffect(() => {
    setValue('bgColor', colorPicker)
    setValue('textColor', colorPickerText)
  }, [colorPicker, colorPickerText])

  useEffect(() => {
    setValue('url', url as any)
    setValue('bgColor', bgColor as any)
    setValue('textColor', textColor as any)
    setValue('openInNewTab', openInNewTab)
  }, [url, bgColor, textColor, setValue, openInNewTab])

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="overlay">
          <Dialog.Content className="container">
            <Dialog.Title className="DialogTitle"></Dialog.Title>
            <form onSubmit={handleSubmit(onSubmit)} className="card flex">
              <p className={'title'}>Añadir botón</p>

              <div className={'flex flex-col gap-4'}>
                <fieldset className="w-full">
                  <p className={'legend'}>URL del botón</p>
                  <input
                    type="url"
                    placeholder="https://www.google.com"
                    className="w-full rounded-md border border-gray-300 p-2"
                    {...register('url')}
                  />
                </fieldset>

                <div className="flex w-full flex-row gap-2">
                  <p className={'legend'}>Abrir link en nueva tab</p>
                  <Switch
                    checked={watchOpenInNewTab}
                    defaultChecked={false}
                    onCheckedChange={handleChangeSwitch}
                  />
                </div>

                <div className="flex w-full flex-row gap-2">
                  <p className={'legend'}>Color de fondo</p>
                  <div className={'my-auto'}>
                    <div
                      className={''}
                      onClick={() => {
                        setColorPickerOpen(!colorPickerOpen)
                        setColorPickerTextOpen(false)
                      }}>
                      <div
                        style={{ backgroundColor: colorPicker }}
                        className={`border-1 h-[14px] w-[36px] border`}
                      />
                    </div>

                    {colorPickerOpen ? (
                      <div className={'z-2 absolute'}>
                        <div
                          className={'t-0 r-0 b-0 l-0 fixed'}
                          onClick={() => {
                            setColorPickerOpen(false)
                            setColorPickerTextOpen(false)
                          }}
                        />
                        <SketchPicker
                          color={colorPicker}
                          onChange={(c) => {
                            setColorPicker(c.hex)
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex w-full flex-row gap-2">
                  <p className={'legend'}>Color del texto</p>
                  <div className={'my-auto'}>
                    <div
                      className={''}
                      onClick={() => {
                        setColorPickerTextOpen(!colorPickerTextOpen)
                        setColorPickerOpen(false)
                      }}>
                      <div
                        style={{ backgroundColor: colorPickerText }}
                        className={`border-1 h-[14px] w-[36px] border`}
                      />
                    </div>

                    {colorPickerTextOpen ? (
                      <div className={'z-2 absolute'}>
                        <div
                          className={'t-0 r-0 b-0 l-0 fixed'}
                          onClick={() => {
                            setColorPickerTextOpen(false)
                            setColorPickerTextOpen(false)
                          }}
                        />
                        <SketchPicker
                          color={colorPickerText}
                          onChange={(c) => {
                            setColorPickerText(c.hex)
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="bottom-buttons">
                  <Dialog.Close asChild>
                    <motion.button className="flex items-center justify-center rounded border border-gray-400 p-2 px-5 text-sm text-black disabled:bg-gray-400">
                      {'Cancelar'}
                    </motion.button>
                  </Dialog.Close>
                  <button onClick={onRemoveButton} className={'remove-button'}>
                    {'Quitar botón'}
                  </button>
                  <button
                    disabled={!isValid}
                    type="submit"
                    className={'confirm-button' + (isValid ? '' : ' disabled')}>
                    {'Guardar'}
                  </button>
                </div>
              </div>
            </form>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
