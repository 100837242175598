import { useRouter } from 'next/navigation'

export function Link({ attributes, children, element, style, onClickAction }) {
  const router = useRouter()

  return (
    <>
      {element.openInNewTab && (
        <a
          className="text-blue-secondary underline"
          href={element.url}
          target={'_blank'}
          style={style}
          onClick={async (e) => {
            if (onClickAction) await onClickAction()
          }}
          {...attributes}>
          {children}
        </a>
      )}

      {!element.openInNewTab && (
        <span
          className="cursor-pointer text-blue-secondary"
          style={style}
          onClick={async (e) => {
            if (onClickAction) await onClickAction()
            router.push(element.url)
          }}
          {...attributes}>
          {children}
        </span>
      )}
    </>
  )
}
