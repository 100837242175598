'use client'
import React, {
  AnimationEventHandler,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  forwardRef,
} from 'react'
import {
  styled,
  VariantsColorsOpacityRelation,
  VariantsColorsRelation,
} from '../config'
import clsx, { ClassValue } from 'clsx'
import { motion } from 'framer-motion'
import { Loading } from './loading'

export const Button = styled('button', {
  // Reset
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  cursor: 'pointer',
  appearance: 'none',
  display: 'inline-flex',
  lineHeight: '1',
  margin: '0',
  padding: '0',
  border: '0',
  outline: 'none',
  textDecoration: 'none',
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  //Shared
  borderRadius: '300px',
  height: '$40',
  width: 'auto',
  fontSize: '$button',
  px: '$large',
  spacingX: '13px',

  '&:disabled': {
    opacity: 0.7,
    pointerEvents: 'none',
  },
  variants: {
    size: {
      circleSm: {
        square: '$32',
      },
      circleMd: {
        square: '$40',
      },
      circleLg: {
        square: '$48',
      },
      sm: {
        height: '24px',
      },
      md: {
        height: '$40',
      },
      lg: {
        height: '$48',
      },
    },
    shape: {
      rectangle: {
        borderRadius: '5px',
      },
    },
    color: {
      Standard: {
        backgroundColor: '$blue100',
        '&:hover': {
          backgroundColor: '$blue100',
        },
      },
      Simulacrum: {
        backgroundColor: '$green100',
        '&:hover': {
          backgroundColor: '$green100',
        },
      },
      Study: {
        backgroundColor: '$purple100',
        '&:hover': {
          backgroundColor: '$purple100',
        },
      },
      black: {
        backgroundColor: '$black',
        '&:hover': {
          backgroundColor: '$black',
        },
      },
      grey: {
        backgroundColor: '$grey5',
        '&:hover': {
          backgroundColor: '$grey10',
          color: '#9D9D9D !important',
          opacity: '0.6',
        },
        color: '#9D9D9D !important',
        opacity: '0.6',
      },
      greyDark: {
        backgroundColor: '#EAEAF4',
        '&:hover': {
          backgroundColor: '#EAEAF4',
          color: '#1B2026 !important',
        },
        color: '#1B2026 !important',
      },
      green: {
        backgroundColor: '#10A489',
        '&:hover': {
          backgroundColor: '#EAEAF4',
          color: 'white !important',
        },
        color: 'white !important',
      },
      neutro: {
        backgroundColor: 'rgba(246, 246, 246, 1)',
        color: '#505458 !important',
        '&:hover': {
          backgroundColor: 'rgba(246, 246, 246, .7)',
          color: '#505458',
        },
      },
      transparent: {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      purple: {
        backgroundColor: '#5368E5',
        '&:hover': {
          backgroundColor: '#5368E5',
        },
      },
      blue: {
        backgroundColor: '#384FD3',
        '&:hover': {
          backgroundColor: '#384FD3',
        },
      },
    },
    lowOpacity: {
      true: {},
      false: {},
    },
    outlined: {
      true: {
        background: '$white',
        border: '1px solid rgba(0,0,0,.25)',
      },
      false: {
        background: 'inherit',
        border: 'none',
      },
    },
    variant: {
      primary: {
        boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.05)',
        color: '$white',
        padding: '10px 20px',
        '& > svg': {
          stroke: '$white',
        },
        '&:hover': {
          color: '$white',
        },
        '@bp1': {
          padding: '0px 12px',
        },
      },
      secondary: {
        border: '1px solid #e1e0e4',
        justifyContent: 'flex-start',
        color: '$black',
        px: '16px',
        '& > svg': {
          stroke: '$black',
        },
        '&:hover': {
          color: '$black',
        },
      },
      transparent: {
        backgroundColor: 'transparent',
        color: '$black',

        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:active': {},
        '&:focus': {},
      },
      admin: {
        borderRadius: '$1',
      },
    },
  },
  compoundVariants: [
    // primary compound variants
    ...VariantsColorsRelation.map(({ color, value }) => ({
      color,
      outlined: true,
      css: {
        color: value,
        background: '$white',
        '&:hover': {
          color: '$white',
          border: '1px solid transparent',
          svg: {
            stroke: '$white',
          },
        },
        svg: {
          stroke: value,
        },
      },
    })),

    ...VariantsColorsOpacityRelation.map(({ color, value }) => {
      const vibrantColorValue = VariantsColorsRelation.find(
        ({ color: vibrantColor }) => vibrantColor === color
      )?.value
      return {
        color,
        lowOpacity: true,
        css: {
          boxShadow: 'none',
          background: value,
          color: vibrantColorValue,
          '&:hover': {
            background: value,
            color: vibrantColorValue,
            border: '1px solid transparent',
            svg: {
              stroke: vibrantColorValue,
            },
          },
          svg: {
            stroke: vibrantColorValue,
          },
        },
      }
    }),

    // Circle compound variants
    ...VariantsColorsRelation.flatMap(({ color, value }) =>
      ['circleSm', 'circleMd', 'circleLg'].map((size) => ({
        color,
        size,
        css: {
          padding: 0,
          svg: {
            stroke: value,
          },
        },
      }))
    ),

    // Transparent compound variants
    ...VariantsColorsRelation.map(({ color, value }) => ({
      variant: 'transparent',
      color,
      css: {
        color: value,
        background: 'transparent',
        '&:hover': {
          background: 'transparent',
        },
      },
    })),
  ],

  defaultVariants: {
    variant: 'primary',
    color: 'Standard',
    size: 'md',
  },
})

export type CommonButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  isLoading?: boolean
}

export const OutlineButton: FC<CommonButtonProps> = ({
  children,
  className,
  ...props
}) => (
  <button
    className={clsx([
      'flex h-10 items-center rounded-md border border-black/10 bg-white px-4 leading-none text-black',
      className,
    ])}
    {...props}>
    {children}
  </button>
)

export type PrimaryButtonProps = CommonButtonProps & {
  className?: ClassValue
  isLoading?: boolean
  disabled?: boolean
}

// eslint-disable-next-line react/display-name
export const PrimaryButton = forwardRef<HTMLButtonElement, PrimaryButtonProps>(
  ({ children, className, disabled, isLoading, ...props }, ref) => (
    <motion.button
      {...(props as AnimationEventHandler<HTMLButtonElement>)}
      whileTap={!disabled ? { scale: 0.95 } : {}}
      disabled={disabled}
      className={clsx([
        'flex h-10 items-center space-x-2 rounded-md bg-admin-blue px-4 leading-none text-white',
        className,
      ])}
      style={{
        background: disabled ? '#bcbcbc' : undefined,
      }}
      ref={ref}>
      {isLoading && <Loading />}
      <span> {children}</span>
    </motion.button>
  )
)
